import React from "react";

import { ShopProvider } from "./src/context/newContext";
import "./src/styles/global.css";

export { wrapPageElement } from "./src/wrap-page-element";

export const wrapRootElement = ({ element }) => (
  <ShopProvider>{element}</ShopProvider>
);
