import React, { createContext, useEffect, useState } from "react";
import { createStorefrontApiClient } from "@shopify/storefront-api-client";

const client = createStorefrontApiClient({
  storeDomain: process.env.GATSBY_SHOPIFY_STORE_URL,
  apiVersion: process.env.GATSBY_STOREFRONT_VERSION,
  publicAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
});

const isBrowser = typeof window !== "undefined";
const localStorageKey = "shopify_cart_id";

// Define cart fragment to be reused across queries
const cartFragment = `
  fragment CartFields on Cart {
    id
    checkoutUrl
    totalQuantity
    lines(first: 10) {
      edges {
        node {
          id
          quantity
          merchandise {
            ... on ProductVariant {
              id
              title
              image {
                url
                altText
              }
              price {
                amount
                currencyCode
              }
              product {
                title
                handle
                images(first: 1) {
                  edges {
                    node {
                      url
                      altText
                    }
                  }
                }
              }
            }
          }
          attributes {
            key
            value
          }
        }
      }
    }
    cost {
      totalAmount {
        amount
        currencyCode
      }
    }
  }
`;

const defaultValues = {
  client,
  loading: false,
  cart: null,
  didJustAddToCart: false,
  addVariantToCart: async () => {},
  updateLineItem: async () => {},
  removeLineItem: async () => {},
};

export const ShopContext = createContext(defaultValues);

export const ShopProvider = ({ children }) => {
  const [cart, setCart] = useState(null);
  const [loading, setLoading] = useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = useState(false);

  useEffect(() => {
    const initializeCart = async () => {
      const existingCartId = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCartId) {
        try {
          // Fetch existing cart
          const { data } = await client.request(
            `
             query GetCart($cartId: ID!) {
               cart(id: $cartId) {
                 ...CartFields
               }
             }
             ${cartFragment}
           `,
            {
              variables: {
                cartId: existingCartId,
              },
            }
          );

          if (data?.cart) {
            setCart(data.cart);
            return;
          }
        } catch (error) {
          console.error("Error fetching cart:", error);
          localStorage.removeItem(localStorageKey);
        }
      }

      // Create new cart if none exists
      try {
        console.log("Creating new cart...");
        const { data } = await client.request(`
           mutation CartCreate {
             cartCreate {
               cart {
                 ...CartFields
               }
             }
           }
           ${cartFragment}
         `);

        console.log("Cart creation response:", data);
        if (data?.cartCreate?.cart) {
          const newCart = data.cartCreate.cart;
          if (isBrowser) {
            localStorage.setItem(localStorageKey, newCart.id);
          }
          setCart(newCart);
        }
      } catch (error) {
        console.error("Error creating cart:", error);
      }
    };

    initializeCart();
  }, []);

  const addVariantToCart = async (
    variantId: string,
    quantity: string,
    customAttributes?: any
  ) => {
    try {
      setLoading(true);
      console.log("Adding to cart:", { variantId, quantity, customAttributes });

      if (!cart?.id) {
        console.log("No cart exists, creating new cart");
        const { data: cartData } = await client.request(`
           mutation CartCreate {
             cartCreate {
               cart {
                 ...CartFields
               }
             }
           }
           ${cartFragment}
         `);

        if (cartData?.cartCreate?.cart) {
          setCart(cartData.cartCreate.cart);
        }
      }

      const { data } = await client.request(
        `
         mutation CartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
           cartLinesAdd(cartId: $cartId, lines: $lines) {
             cart {
               ...CartFields
             }
             userErrors {
               field
               message
             }
           }
         }
         ${cartFragment}
       `,
        {
          variables: {
            cartId: cart.id,
            lines: [
              {
                merchandiseId: variantId,
                quantity: parseInt(quantity, 10),
                attributes: customAttributes
                  ? Object.entries(customAttributes).map(([key, value]) => ({
                      key,
                      value: String(value),
                    }))
                  : [],
              },
            ],
          },
        }
      );

      if (data?.cartLinesAdd?.cart) {
        setCart(data.cartLinesAdd.cart);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 1000);
      }

      if (data?.cartLinesAdd?.userErrors?.length > 0) {
        console.error("User errors:", data.cartLinesAdd.userErrors);
        throw new Error(data.cartLinesAdd.userErrors[0].message);
      }
    } catch (error) {
      console.error("Failed to add to cart:", error);
      throw error; // Re-throw to be handled by the component
    } finally {
      setLoading(false); // Always reset loading state
    }
  };

  const removeLineItem = async (cartId: string, lineId: string) => {
    setLoading(true);
    try {
      const { data } = await client.request(
        `
      mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
        cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
      ${cartFragment}
    `,
        {
          variables: {
            cartId,
            lineIds: [lineId],
          },
        }
      );

      if (data?.cartLinesRemove?.cart) {
        setCart(data.cartLinesRemove.cart);
      }

      if (data?.cartLinesRemove?.userErrors?.length > 0) {
        console.error("User errors:", data.cartLinesRemove.userErrors);
      }
    } catch (error) {
      console.error("Failed to remove line item:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateLineItem = async (
    cartId: string,
    line: any,
    quantity: string
  ) => {
    setLoading(true);
    try {
      const { data } = await client.request(
        `
      mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
        cartLinesUpdate(cartId: $cartId, lines: $lines) {
          cart {
            ...CartFields
          }
          userErrors {
            field
            message
          }
        }
      }
      ${cartFragment}
    `,
        {
          variables: {
            cartId,
            lines: [
              {
                id: line.id,
                quantity: parseInt(quantity, 10),
              },
            ],
          },
        }
      );

      if (data?.cartLinesUpdate?.cart) {
        setCart(data.cartLinesUpdate.cart);
      }

      if (data?.cartLinesUpdate?.userErrors?.length > 0) {
        console.error("User errors:", data.cartLinesUpdate.userErrors);
      }
    } catch (error) {
      console.error("Failed to update line item:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ShopContext.Provider
      value={{
        client,
        loading,
        cart,
        didJustAddToCart,
        addVariantToCart,
        updateLineItem,
        removeLineItem,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export default ShopProvider;
